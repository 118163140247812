import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import { Component, Watch } from 'vue-property-decorator'

import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'SessionsImportView',
  computed: {
    ...mapState('registration', ['currentImport', 'importErrors']),
    ...mapState('formbuilder', ['importEventFields'])
  }
})

export default class SessionsImportView extends GtrSuper {
  currentImport!: Record<string, any>;

  importEventFields!: Record<string, any>;

  data () {
    return {
      loading: false,
      submitting: false,
      import_first_row: 0,
      mode: '',
      base_update_on: '',
      import_errors: '',
      yesno: [
        {
          label: 'Yes',
          value: 1
        },
        {
          label: 'No',
          value: 0
        }
      ],
      importModes: [
        {
          label: 'IMPORT_ONLY',
          value: 'IMPORT_ONLY'
        },
        {
          label: 'IMPORT AND UPDATE',
          value: 'IMPORT_AND_UPDATE'
        }
      ],
      fieldsPrint: [],
      importFile: '',
      event_uuid: this.$route.params.event_uuid,
      mapping: [],
      _currentImport: null,
      fieldsIndexed: [],
      importTab: 0,
      tableSearch: ''
    }
  }

  mounted () {
    this.getImportEventFields()
    this.getImport()
    this.setActiveTab()
  }

  get updateBasedOnFields () {
    return [
      { text: 'Name', value: 'name' },
      { text: 'Barcode', value: 'barcode' }
    ]
  }

  @Watch('importEventFields', { immediate: true })
  onImportEventFieldsChange (newVal: any) {
    this.$data.fieldsPrint = []
    this.$data.fieldsIndexed = []
    for (const fieldIndex in newVal) {
      const field = newVal[fieldIndex]
      this.$data.fieldsPrint.push({ text: field.label, value: field.field, on_form: field.on_form, custom_field: field.custom_field })
      this.$data.fieldsIndexed[field.field] = field.field
    }
  }

  @Watch('importErrors', { immediate: true })
  onImportErrorsChange (newVal: any) {
    this.$data.import_errors = newVal
  }

  @Watch('currentImport', { immediate: true })
  onCurrentImportChange (newVal: any) {
    this.$data._currentImport = newVal
    this.$data.mode = newVal.mode
    this.$data.base_update_on = newVal.base_update_on
    this.$data.mapping = newVal.mapping?.length ? newVal.mapping : new Array(newVal.data[0]?.length)
    this.$data.import_first_row = !newVal.import_first_row ? 0 : 1
    this.setActiveTab()
    if (newVal.status === 'FINISHED') {
      this.$data.submitting = false
    }
    this.mapFields()
  }

  private removeUnusedColumns (data: any[][]): any[][] {
    const filteredData: any[][] = []
    const columnsToRemove: number[] = []

    this.$data.mapping.forEach((field, index: number) => {
      if (!field) {
        columnsToRemove.push(index)
      }
    })

    data.forEach((row: any[]) => {
      filteredData.push(row.filter((element, index) => !columnsToRemove.includes(index)))
    })

    return filteredData
  }

  get importHeaders () {
    if (this.$data.mapping.length) {
      return this.$data.mapping.filter(field => !!field)
    }
    return []
  }

  get importData () {
    if (((this as any)?.currentImport?.data || []).length) {
      return this.removeUnusedColumns((this as any).currentImport.data.slice(1))
    }
    return []
  }

  private async getImportEventFields () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        table: 'sessions'
      }
      await this.$store.dispatch('formbuilder/getImportEventFields', payload)
      this.mapFields()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private allUndefined (arr) {
    return arr.every(element => element === undefined)
  }

  private mapFields () {
    const newVal = this.$data._currentImport
    if (this.$data.mapping !== undefined && newVal.data !== undefined && this.$data.mapping && this.allUndefined(this.$data.mapping) && newVal.data.length > 0) {
      const dataCur = newVal.data[0]
      for (const itemIndex in dataCur) {
        const item = dataCur[itemIndex]
        if (typeof item === 'string') {
          for (const fieldIn in this.$data.fieldsIndexed) {
            if (item.toLowerCase() === fieldIn) {
              this.$data.mapping[itemIndex] = fieldIn
            }
          }
        }
      }
    }
  }

  private async getImport () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        import_uuid: this.$route.params.import_uuid
      }
      await this.$store.dispatch('registration/getImport', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  fileName (url) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  async startImport () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        import_uuid: this.$route.params.import_uuid,
        data: {
          import_first_row: this.$data.import_first_row,
          mode: this.$data.mode,
          base_update_on: this.$data.base_update_on,
          mapping: this.$data.mapping,
          start_import: true
        }
      }
      await this.$store.dispatch('registration/updateImport', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async updateImport () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        import_uuid: this.$route.params.import_uuid,
        data: {
          import_first_row: this.$data.import_first_row,
          mode: this.$data.mode,
          base_update_on: this.$data.base_update_on,
          mapping: this.$data.mapping,
          start_import: false
        }
      }
      await this.$store.dispatch('registration/updateImport', payload)
      Container.get(Notification).success('Import successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async downloadFileUrl () {
    window.location.href = this.$data._currentImport.file_url
  }

  handleGoBackToImports () {
    this.$router.push({
      name: 'level-two.modules.track.sessions.import',
      params: {
        event_uuid: this.$route.params.event_uuid
      }
    })
  }

  get importTableHeaders () {
    if (this.$data.mapping.length) {
      const headers: Record<string, any> = []
      for (let i = 0; i < this.$data.mapping.length; i++) {
        const header = this.$data.mapping[i]
        if (header) {
          headers.push({
            align: 'start',
            sortable: true,
            text: this.importEventFields?.[header]?.label ?? this.$options?.filters?.capitalizeAndRemoveUnderscores(header),
            value: header,
            class: 'header-no-wrap'
          })
        }
      }
      headers.unshift({
        align: 'start',
        sortable: true,
        text: '#',
        value: 'rowIndex',
        class: 'header-no-wrap'
      })
      // headers.push({
      //   align: 'start',
      //   sortable: false,
      //   text: 'Result',
      //   value: 'result',
      //   class: 'header-no-wrap'
      // })
      return headers
    }
    return []
  }

  get importTableFailuresHeaders () {
    if (this.$data.mapping.length) {
      const headers: Record<string, any> = []
      headers.push({
        align: 'start',
        sortable: true,
        text: '#',
        value: 'rowIndex',
        class: 'header-no-wrap'
      })
      headers.push({
        align: 'start',
        sortable: true,
        text: 'Error',
        value: 'error',
        class: 'header-no-wrap'
      })
      return headers
    }
    return []
  }

  get importTableData () {
    if (this.currentImport?.data?.length && this.currentImport?.row_result_mapping) {
      let data: any
      if (this.currentImport.import_first_row) {
        data = this.removeUnusedColumns(this.currentImport.data)
      } else {
        data = this.removeUnusedColumns(this.currentImport.data.slice(1))
      }
      const fields = [...this.$data.mapping].filter(field => field !== null)
      fields.unshift('rowIndex')
      fields.push('result')
      fields.push('error')
      fields.push('uuid')
      const tableData: Record<string, any> = []
      if (this.currentImport.import_first_row) {
        for (let i = 0; i < data.length; i++) {
          data[i].unshift(i)
          this.currentImport.row_result_mapping[i]?.status ? data[i].push(this.currentImport.row_result_mapping[i].status) : data[i].push(null)
          this.currentImport.row_result_mapping[i]?.error ? data[i].push(this.currentImport.row_result_mapping[i].error) : data[i].push(null)
          data[i].push(this.currentImport.row_result_mapping[i]?.uuid)
          const item: any = { ...data[i] }
          for (let j = 0; j < fields.length; j++) {
            const field = fields[j]
            if (field === 'rowIndex') {
              item[field] = item[j] + 1
            } else {
              item[field] = item[j]
            }
            delete item[j]
          }
          tableData.push(item)
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          data[i].unshift(i + 1)
          this.currentImport.row_result_mapping[i + 1]?.status ? data[i].push(this.currentImport.row_result_mapping[i + 1].status) : data[i].push(null)
          this.currentImport.row_result_mapping[i + 1]?.error ? data[i].push(this.currentImport.row_result_mapping[i + 1].error) : data[i].push(null)
          data[i].push(this.currentImport.row_result_mapping[i + 1]?.uuid)
          const item: any = { ...data[i] }
          for (let j = 0; j < fields.length; j++) {
            const field = fields[j]
            item[field] = item[j]
            delete item[j]
          }
          tableData.push(item)
        }
      }
      return tableData
    }
    return []
  }

  get totalFields (): number {
    if (this.$data.mapping?.length) {
      return this.$data.mapping.length
    }
    return 0
  }

  get unmappedFields (): number {
    if (this.$data.mapping?.length) {
      let numOfUnmapped = 0
      for (let i = 0; i < this.$data.mapping.length; i++) {
        if (this.$data.mapping[i] === undefined || this.$data.mapping[i] === null) {
          numOfUnmapped++
        }
      }
      return numOfUnmapped
    }
    return 0
  }

  get totalRows () {
    if (this.currentImport?.data) {
      if (this.$data.import_first_row) {
        return this.currentImport.data.length
      } else {
        return this.currentImport.data.length - 1
      }
    }
  }

  get importTableAdds () {
    if (this.importTableData && this.currentImport?.inserts) {
      const adds: any[] = []
      for (let i = 0; i < this.importTableData.length; i++) {
        for (let j = 0; j < this.currentImport.inserts.length; j++) {
          if (this.importTableData[i].name === this.currentImport.inserts[j].savedRecord.name) {
            adds.push(this.importTableData[i])
          }
        }
      }
      return adds
    }
    return []
  }

  get importTableUpdates () {
    if (this.importTableData && this.currentImport?.updates) {
      const updates: any[] = []
      for (let i = 0; i < this.importTableData.length; i++) {
        for (let j = 0; j < this.currentImport.updates.length; j++) {
          if (this.importTableData[i].name === this.currentImport.updates[j].savedRecord.name) {
            updates.push(this.importTableData[i])
          }
        }
      }
      return updates
    }
    return []
  }

  get importTableFailures () {
    if (this.importTableData && this.currentImport?.failures) {
      const failures: any[] = []
      for (let i = 0; i < this.importTableData.length; i++) {
        if (this.importTableData[i].result === 'FAILED') {
          failures.push(this.importTableData[i])
        }
      }
      return failures
    }
    return []
  }

  get totalAdded () {
    if (this.currentImport?.totals) {
      return this.currentImport.totals.total_added
    }
    return 0
  }

  get totalUpdated () {
    if (this.currentImport?.totals) {
      return this.currentImport.totals.total_updated
    }
    return 0
  }

  get totalFailed () {
    if (this.currentImport?.totals) {
      return this.currentImport.totals.total_failed
    }
    return 0
  }

  get importTabs () {
    return [
      {
        id: 0,
        text: `Adds (${this.totalAdded ? this.totalAdded : 0})`
      },
      {
        id: 1,
        text: `Updates (${this.totalUpdated ? this.totalUpdated : 0})`
      },
      {
        id: 2,
        text: `Failures (${this.totalFailed ? this.totalFailed : 0})`
      }
    ]
  }

  get totalJobs () {
    if (this.currentImport?.progress) {
      const indexOfTotalJobsFinished = this.currentImport.progress.indexOf('total jobs finished:')
      return this.currentImport.progress.substring(indexOfTotalJobsFinished + 21) // hack for now
    }
    return ''
  }

  get emptyStateMessage () {
    if (this.$data.importTab === 0) {
      return 'No adds.'
    } else if (this.$data.importTab === 1) {
      return 'No updates.'
    } else if (this.$data.importTab === 2) {
      return 'No failures.'
    }
    return 'No data available.'
  }

  setActiveTab () {
    if (this.totalAdded === 0 && this.totalFailed === 0 && this.totalUpdated > 0) {
      this.$data.importTab = 1
    }
    if (this.totalFailed > 0) {
      this.$data.importTab = 2
    }
  }

  handleFilterAdded (): void {
    this.$data.importTab = 0
  }

  handleFilterUpdated (): void {
    this.$data.importTab = 1
  }

  handleFilterFailed (): void {
    this.$data.importTab = 2
  }

  handleTabSelection (tab: number): void {
    if (tab === 0) {
      this.handleFilterAdded()
    } else if (tab === 1) {
      this.handleFilterUpdated()
    } else if (tab === 2) {
      this.handleFilterFailed()
    }
  }
}
